import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router,Routes, Route,Navigate } from 'react-router-dom';
import './index.css';
import { Navbar } from './Components/Navbar';
import { Footer } from './Components/Footer';
import { Home, OurStory, FoodServices, Contact, Career, Restaurant } from './Pages';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path='/ourstory' element={<OurStory/>}></Route>
        <Route path='/foodservices' element={<FoodServices/>}></Route>
        <Route path='/career' element={<Career />}></Route>
        <Route path='/restaurant' element={<Restaurant />}></Route>
        <Route path='/contact' element={<Contact />}></Route>
        <Route path='*' element={<Navigate to='/' />}></Route>
      </Routes>
      <Footer/>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
