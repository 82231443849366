import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay} from 'swiper/modules';

export const FoodServicesSlider = () => {
  return (<>
    <Swiper
        loop={true}
        grabCursor={false}
        spaceBetween={0}
        speed={2000}
        autoplay={{ delay:5000, waitForTransition: true, }}
        modules={[Autoplay]}
        className="container-fluid FS_cont_9 Our_story_slider text-center">
          <SwiperSlide>
            <div className='col'> 
            <h2 className='number pb-3'>1</h2>
            <div className='wrapper wrapper1'>
                <img src='/foodservices/roti_mobile.jpg' className='img-fluid' />
            </div> 
            <h3 className='sheading py-3'>Ingredients Handling & Storing</h3>
            <p>Our ingredients are bought, examined, arranged, and stored in accordance with the established guidelines, using recognised processes and with the appropriate labelling.</p>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='col'> 
            <h2 className='number pb-3'>2</h2>
            <div className='wrapper wrapper1'>
                <img src='/foodservices/capsicum_mobile.jpg' className='img-fluid' />
            </div>
            <h3 className='sheading py-3'>Kitchen Hygiene & Prep</h3>
            <p>Our prep staff manage the food preparation, cooking, and kitchen maintenance under the guidance of professional Commis chefs with extensive experience.</p>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='col'> 
            <h2 className='number pb-3'>3</h2>
            <div className='wrapper wrapper1'>
                <img src='/foodservices/service_mobile.jpg' className='img-fluid' />
            </div>
            <h3 className='sheading py-3'>Service Handling & Supervising</h3>
            <p>We design cook, hold, and serve plans based on the institution's needs, with meticulous service standards, food setup, and clearance in the dining areas.</p>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='col'>
            <h2 className='number pb-3'>4</h2>
            <div className='wrapper wrapper1'>
                <img src='/foodservices/handling_mobile.jpg' className='img-fluid' />
            </div>
            <h3 className='sheading py-3'>Equipment Handling & Storing</h3>
            <p>Our well-trained staff handle cutting-edge kitchens effectively, serving nutritious meals and sticking to best practices when washing and storing production and service wares.</p>
            </div>
          </SwiperSlide>
    </Swiper>
  </>)
}