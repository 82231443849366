import React,{ useState,useEffect,useRef } from 'react';
import { Navigate, useNavigate } from 'react-router';
import clip0 from "../assets/home.mp4";
import fallback from "../assets/fallback.jpg";
import HomeSlider from '../Components/HomeSlider';
import { Fade } from 'react-awesome-reveal';


const Home = () => {

 const [matches, setMatches] = useState(window.matchMedia("(min-width: 320px)").matches)
 useEffect(() => {window.matchMedia("(min-width: 320px)").addEventListener('change', e => setMatches( e.matches ));}, []);

 const navigate = useNavigate();
 const food=() => { navigate('/foodservices')}
 const ourstory=() => { navigate('/ourstory')}
 const restaurant=() => { navigate('/restaurant')}
 const career=() => { navigate('/career')}

 const [showButton, setShowButton] = useState(true);
 const videoRef = useRef();
 const handlePlay = () => {
    setShowButton(!showButton);
     videoRef.current.play();
 }
 const handlePause = () => {
     videoRef.current.pause();
     setShowButton(!showButton);
    }

  return (<>

     <section className='container-fluid p-0 h_vid_cont position-relative'>
        {showButton &&<div onClick={handlePlay}> <img src='play.png' className='img-fluid top-50 start-50 translate-middle play'/> </div> }
        {matches && <video src={clip0} loop poster={fallback} className='home_video' ref={videoRef} onClick={handlePause}> </video> }
    </section> 
        
      <section className='container home_cont_2'>
        <div className='row justify-content-center'>
          <div className='col col-sm-12 col-md-6 col-lg-6'>
            <Fade delay={500}>
            <h2 className='font_3'>Crafting <span>exclusive</span> culinary experiences for <span>over 2 decades.</span></h2>
            </Fade>
          </div>
          <div className='col col-sm-12 col-md-6 col-lg-6 pt-3'>
            <Fade cascade delay={800}>
            <p>Our unwavering pursuit of perfection is the motivating force behind all of our culinary endeavors.</p>
            <p>Our professionalism and continuous innovation, together with our insatiable passion, enable us to deliver exceptional quality to our loyal patrons.</p>
            </Fade>
          </div>
        </div>
      </section>

      <section className='container hcont3'>
        <div className='row'>
          <div className='col col-sm-8 col-md-3 col-lg-3' onClick={food}>
          <div className='wrapper'>
            <img src='/home/food_services.jpg' className='img-fluid'/>
            </div>
            <h4>FOOD SERVICES</h4>
            <p>Transforming institutional food services by focusing on quality, freshness, and hygiene standards that exceed expectations.</p>
          </div>
          <div className='col col-sm-8 col-md-3 col-lg-3' onClick={ourstory}>
            <div className='wrapper'>
            <img src='/home/our_story.jpg' className='img-fluid'/>
            </div>
            <h4>OUR STORY</h4>
            <p>Presence of over two decades, diverse service offerings, and a steadfast commitment to excellence, is what sets us apart.</p>
          </div>
          <div className='col col-sm-8 col-md-3 col-lg-3' onClick={restaurant}>
          <div className='wrapper'>
            <img src='/home/restaurants.jpg' className='img-fluid'/>
            </div>
            <h4>BISTROS</h4>
            <p>Our insatiable appetite for new ideas means we are always on the lookout for the next interesting culinary adventure.</p>
          </div>
          <div className='col col-sm-8 col-md-3 col-lg-3' onClick={career}>
          <div className='wrapper'>
            <img src='/home/careers.jpg' className='img-fluid'/>
            </div>
            <h4>CAREERS</h4>
            <p>We are committed to establishing a workplace where everyone, irrespective of their background, can prosper.</p>
          </div>
        </div>
        <HomeSlider/>
      </section>

      <section className='container-fluid hcont4 mb-4'>
        <div className='row justify-content-center'>
          <div className='col col-sm-12 col-md-12 col-lg-8'>
            <h3 className='font_3'>Good food doesn't just nourish the body.<br/><span>It enlivens the soul!</span></h3>
          </div>
        </div>
      </section>

    </>
  )
}
export default Home;