import React from 'react';
import { useNavigate } from 'react-router';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay} from 'swiper/modules';

const HomeSlider = () => {
  const navigate = useNavigate();
  const food=() => { navigate('/foodservices')}
  const ourstory=() => { navigate('/ourstory')}
  const restaurant=() => { navigate('/restaurant')}
  const career=() => { navigate('/career')}
  return (<>
        <Swiper
        loop={true}
        grabCursor={false}
        spaceBetween={30}
        speed={1000}
        autoplay={{ delay:2000, waitForTransition: true, }}
        modules={[Autoplay]}
        className="container-fluid Our_story_slider home_slider text-center">
          <SwiperSlide>
          <div className='col col-sm-8' onClick={food}>
          <div className='wrapper'>
            <img src='/home/food_services.jpg' className='img-fluid'/>
            </div>
            <h4>FOOD SERVICES</h4>
            <p>Transforming institutional food services by focusing on quality, freshness, and hygiene standards that exceed expectations.</p>
          </div>
          </SwiperSlide>
          
          <SwiperSlide>
          <div className='col col-sm-8 ' onClick={ourstory}>
            <div className='wrapper'>
            <img src='/home/our_story.jpg' className='img-fluid'/>
            </div>
            <h4>OUR STORY</h4>
            <p>Presence of over two decades, diverse service offerings, and a steadfast commitment to excellence, is what sets us apart.</p>
          </div>
          </SwiperSlide>

          <SwiperSlide>
          <div className='col col-sm-8' onClick={restaurant}>
          <div className='wrapper'>
            <img src='/home/restaurants.jpg' className='img-fluid'/>
            </div>
            <h4>BISTROS</h4>
            <p>Our insatiable appetite for new ideas means we are always on the lookout for the next interesting culinary adventure.</p>
          </div>
          </SwiperSlide>

          <SwiperSlide>
          <div className='col col-sm-8' onClick={career}>
          <div className='wrapper'>
            <img src='/home/careers.jpg' className='img-fluid'/>
            </div>
            <h4>CAREERS</h4>  
            <p>We are committed to establishing a workplace where everyone, irrespective of their background, can prosper.</p>
          </div>
          </SwiperSlide>
        </Swiper>
  </>)
}

export default HomeSlider