import React from 'react'

export const Footer = () => {
  return (
    <>    
   <footer className="container-fluid p-3"> 
    <div className='row frow' >
        <div className='col col-sm-9 col-md-8 col-lg-7 align-self-center fssai'>
            <img src='/fssai.png'  className='img-fluid'/>
            <p className='lead'><small>© 2023 Copyright Basil Hospitality Services | All Rights Reserved.</small></p>
            <p className='lead lead1'>Created by <a href='https://wytefyre.com/' target='new'>Wytefyre</a></p>
        </div>
        <div className='col-sm-3 col-md-4 col-lg-4 align-self-center'>
            <a href='/'><img src='/footer.png' width="110px" className='img-fluid ft_logo' align="right" alt='Basil logo'/></a>
        </div>
    </div>
  </footer></>)}
