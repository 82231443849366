import React from 'react';
import OurStorySlider from '../Components/OurStorySlider';
import { Fade } from "react-awesome-reveal";


const OurStory = () => {
  return (<>
    <section className='container-fluid ourstory_container_1 pt-0 mt-0'>
      <div className='row justify-content-center text-center position-relative'>
        <div className='col col-sm-12 col-md-12 col-lg-12'>
          <h1 className='font_4'>Our Story</h1>
        </div>
        <div className='col col-sm-3 col-md-4 col-lg-3 align-self-start pt-2'>
          <Fade direction='left' duration={2000}>
          <img src='/ourstory/donut.jpg' className='img-fluid' />
          </Fade>
        </div>
        <div className='col col-sm-3 col-md-4 col-lg-3 py-5 align-self-center' id='chesecake'>
          <Fade direction='down' duration={1000}>
          <img src='/ourstory/chesecake.jpg' className='img-fluid' />
          </Fade>
        </div>
        <div className='col col-sm-3 col-md-4 col-lg-3 align-self-start'>
          <Fade direction='right' duration={2000}>
          <img src='/ourstory/noodels.jpg' className='img-fluid' />
          </Fade>
        </div>
      </div>
    </section>
    <section className='ourstory_container_2 '> 
    <img src="/ourstory/cp.png" className='patt position-absolute'/>
    </section>

    <section className='container-fluid ourstory_container_3'>
      <div className='row justify-content-center pt-5 pb-4'>
        <div className='col col-md-6 col-lg-4 align-self-center'>
          <Fade delay={500}>
          <h2 className='pb-3 pt-3'>Culinary <span>Finesse</span> Meets Profound <span>Commitment</span></h2>
          </Fade>
        </div>
        <div className='col col-md-6 col-lg-4 align-self-center py-5'>
          <Fade delay={1000}>
            <p>Basil has been a part of Bengaluru's culinary landscape for over <span>two decades</span>, combining
            culinary skills and <span>traditions, delighting</span> discerning palates, and preserving longstanding <span>relationships</span> with diners.</p>
          </Fade>
        </div>
      </div>
    </section>

    <section className='container-fluid ourstory_container_6 py-5'>

      <div className='row justify-content-center row1'>
        <div className='col col-sm-12 col-md-12 col-lg-10 text-center'>
          <h2>Our <span>service</span> lines include</h2>
          <img src='/ourstory/leaf.png' className='img-fluid py-4 leaf_1' />
        </div>
      </div>  

      <div className='row justify-content-center row2'>

          <div className='col col-lg-3 service_lines'>
            <div className='wrapper'>
            <img src='/ourstory/01.jpg' className='img-fluid'/>
            </div>
            <h4>Institutional Food Services</h4>
            <p>Wholesome menu for cafeteria meals, special occasions, and annual events</p>
          </div>

          <div className='col col-lg-3 service_lines'>
          <div className='wrapper'>
            <img src='/ourstory/02.jpg' className='img-fluid'/>
            </div>
            <h4>Quick Service Restaurants</h4>
            <p>Quick and delicious meals for individuals on the go</p>
          </div>

          <div className='col col-lg-3 service_lines'>
          <div className='wrapper'>
            <img src='/ourstory/03.jpg' className='img-fluid'/>
            </div>
            <h4>Cafes and more</h4>
            <p>Ideal setting for social and professional gatherings over wonderful coffee and food</p>
          </div>
      </div>

      <OurStorySlider/>
    </section>

    <section className='container ourstory_container_4 mb-2'>
      <div className='row justify-content-center'>
        <div className='col col-md-10 col-lg-6'>
          <Fade direction='left' duration={2000}>
          <img src='/ourstory/shakes.jpg' className='img-fluid' />
          </Fade>
        </div>
        <div className='col col-md-10 col-lg-5 align-self-center'>
          <h2 className='pb-5'><span className='itl'>Basil</span> is deeply rooted in an <span className='itl'>unwavering</span> <span>commitment</span> to <span>quality</span> and client satisfaction</h2>
          <Fade cascade delay={800}>
          <p>Our extensive experience in producing delicious culinary experiences and bringing smiles to
          our patrons has led us to believe that food is more than mere <span>nourishment</span>.</p>
          <p>Our concept-based gourmet offerings, through meticulous <span>selection, sourcing, sautéing, and serving, </span> 
        create one-of-a-kind sensory narratives, expertly marrying <span>quality</span> and <span>refinement</span>.</p>
        </Fade>
        </div>
      </div>
    </section>
  </>)
}
export default OurStory;