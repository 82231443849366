import React from 'react';
import { Fade } from 'react-awesome-reveal';

const Contact = () => {
  return (<>
  <div className='conthead'>
  <Fade> 
  <section className='container contact_1 pt-4'>
    <div className='row justify-content-center text-center'>
      <div className='col col-md-12 col-lg-8'>
        <h2 className='px-2 font_4'><Fade>Connect with us</Fade></h2>
        <br/>
        <Fade damping={0.3} delay={600}>
        <p className='px-2'>For business enquiries, vendor queries, service information, feedback, and suggestions, please reach out to us at <a href="mailto:admin@basilhotels.com">admin@basilhotels.com</a></p>
        </Fade>
      </div>
    </div>
  </section>
 <br/>
 </Fade>
{/* <section className='container-fluid contactcontainer2 ps-5 pt-5'>
    <div className='row'>
      <div className='col col-sm-12 col-md-7 col-lg-5 col-xl-4 pb-4'>        
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1098.8658809941574!2d77.57907289178668!3d12.988444996204736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae161605959b43%3A0xfa2bc412ef24362!2sTholasi%20Publications%20Private%20Limited!5e0!3m2!1sen!2sin!4v1691559703906!5m2!1sen!2sin" width="400" height="300" style={{border:'0'}} allowFullScreen={true} loading='lazy' referrerPolicy="no-referrer-when-downgrade"></iframe>     
      </div>    
      <div className='col col-sm-12 col-md-5 col-lg-4 col-xl-5 px-4 pb-4 align-self-end'>
        <h3 className='pb-2 addr font_1'>ADDRESS</h3>
        <address>No.9 West Park Cross Road, Railway Parallel Rd, <br/>Kumara Park East, Seshadripuram,<br/> Bengaluru 560001<br/> Karnataka</address>
        <p><img src="logos/mail.png" alt='mail'/> &nbsp;<a href='mailto:admin@basilhotels.com'>admin@basilhotels.com</a></p>
      </div>
    </div>
  </section>  */}

  <section className='container contact_2  d-flex flex-column'>
    <div className='mx-auto'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1098.8658809941574!2d77.57907289178668!3d12.988444996204736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae161605959b43%3A0xfa2bc412ef24362!2sTholasi%20Publications%20Private%20Limited!5e0!3m2!1sen!2sin!4v1691559703906!5m2!1sen!2sin" width="400" height="300" style={{border:'0'}} allowFullScreen={true} loading='lazy' referrerPolicy="no-referrer-when-downgrade"></iframe>
        <br/><br/>
        <Fade delay={500}>
        <h3 className='py-2 addr font_1'>ADDRESS</h3>
        </Fade>
        <Fade damping={0.5} delay={600}>
        <address>No.9 West Park Cross Road, Railway Parallel Rd, <br/>Kumara Park East, Seshadripuram,<br/> Bengaluru 560001<br/> Karnataka</address>
        <p><img src="logos/mail.png" alt='mail'/> &nbsp;<a href='mailto:admin@basilhotels.com'>admin@basilhotels.com</a></p>  
        </Fade>
      </div>   
  </section>
  </div>

</>)}
export default Contact;