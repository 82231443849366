import React, { useRef,useState,useEffect } from "react";
import Hamburger from "hamburger-react";
import { NavLink} from "react-router-dom";

export const Mobil = () => {
    const [isOpen, setOpen] = useState(false);
    const [Toggle, setToggle] = useState(false);

    let menu = useRef();
    useEffect(() => {
      let handler = (e) => {
        if(!menu.current.contains(e.target)) {
        setOpen(false);
        }
      };
      document.addEventListener("mousedown",handler);
      return() => {
        document.removeEventListener("mousedown",handler);
      }
    })


    // to change burger classes
    const [burger_class, setBurgerClass] = useState("burger-bar unclicked");
    const [menu_class, setMenuClass] = useState("menu hidden")
    const [isMenuClicked, setIsMenuClicked] = useState(true);

      // toggle burger menu change
  const updateMenu = () => {
    if (!isMenuClicked) { 
        setBurgerClass("burger-bar clicked")
        setMenuClass("menu visible")   
    }
    else {
        setBurgerClass("burger-bar unclicked")
        setMenuClass("menu hidden")
    }
    setIsMenuClicked(!isMenuClicked)
}
    return(<>
    <div className="hamburgerN" ref={menu}>
    <Hamburger size={32} duration={0.4} easing="ease-in" toggled={isOpen}  color="#000" onClick={updateMenu} toggle={setOpen} 
    onToggle={toggled => {
         if (toggled) {
           console.log("Menu opened")
        } else {
           // close a menu
           console.log("Menu closed")
        }
      }}
    />
{ isOpen ? <ul className='mobile_list'>
        <li><NavLink to="/" className="nav-link" onClick={() => setOpen(!isOpen)} >HOME</NavLink></li> 
        <li><NavLink to="/ourstory" onClick={() => setOpen(!isOpen)}>OUR STORY</NavLink></li>
        <li><NavLink to="/foodservices" onClick={() => setOpen(!isOpen)}>FOOD SERVICES</NavLink></li>
        <li><NavLink to="/restaurant" onClick={() => setOpen(!isOpen)}>RESTAURANTS</NavLink></li>
        <li><NavLink to="/career" onClick={() => setOpen(!isOpen)}>CAREERS</NavLink></li>
        <li><NavLink to="/contact" onClick={() => setOpen(!isOpen)}>CONTACT</NavLink></li>
      </ul>:null}
    </div>
    <div className="logomobile">
      <a href="/" className="mb-md-0 me-md-auto text-dark text-decoration-none">
        <img src='/logo.png' height="auto" width="100px" alt='logo'/>
      </a>
    </div>
    
    </>)
}