import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay} from 'swiper/modules';

export const ExperienceSlider = () => { 
    return(<>
    <Swiper
        loop={true}
        grabCursor={true}
        speed={1000}
        spaceBetween={50}
        autoplay={{ delay:3000, waitForTransition: true, }}
        modules={[Autoplay]}
        className="container-fluid experienced text-center">
            <SwiperSlide className='slide text-center'>
                <div className='col col-sm-12 col-md-4'>
                    <img src='/foodservices/ourcafe.png' className='img-fluid pb-3' />
                    <h3>CAFETERIA</h3>
                </div>
            </SwiperSlide>
            <SwiperSlide className='slide'>
                <div className='col col-sm-12 col-md-4'>
                    <img src='/foodservices/cafeteria.png' className='img-fluid pb-3' />
                    <h3>OUT OF CAFETERIA</h3>
                </div>
            </SwiperSlide>
            <SwiperSlide className='slide'>
                <div className='col col-sm-12 col-md-4'>
                    <img src='/foodservices/operations.png' className='img-fluid pb-3' />
                    <h3>ANNUAL EVENTS</h3>
                </div>
            </SwiperSlide>
        </Swiper>
    </>)
}