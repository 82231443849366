import React from 'react';
import { Fade } from 'react-awesome-reveal';

const Restaurant = () => {
    return (<>
      <section className='container-fluid d-flex rest_1'>
          <div className='row m-auto text-center'>
            <h2>Dine With Us Soon!</h2>
            <Fade delay={1200}>
              <p>We're excited to launch our latest line of Indian bistros and baked goods. Specialties in this segment will cater to the connoisseurs of South Indian staples, with a twist to satisfy a meat lover's cravings too. Basil also brings to you a select range of baked Indian breads, cookies, goodies, and more - all served up with our exceptional service standards and professionalism. <br/><br/></p>
            </Fade>
          </div>
      </section>
  </>)
}
export default Restaurant;