import React from 'react';
import { Mobil } from './Mobil';
import './../index.css';
import { NavLink } from 'react-router-dom';

export const Navbar = () => {
  return (
    <>

    <header className="container d-flex flex-wrap justify-content-center py-3 mb-2 align-items-center">
      <Mobil/>
      <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none milogo">
        <img src='/logo.png' height="auto" width="120px" alt='logo'/>
      </a>
      
      <ul className='nav navlink'>
        <li className="nav-item"><NavLink to="/" className="nav-link">HOME</NavLink></li>
        <li><NavLink to="/ourstory">OUR STORY</NavLink></li>
        <li><NavLink to="/foodservices">FOOD SERVICES</NavLink></li>
        <li><NavLink to="/restaurant">RESTAURANTS</NavLink></li>
        <li><NavLink to="/career">CAREERS</NavLink></li>
        <li><NavLink to="/contact">CONTACT</NavLink></li>
      </ul>
    </header>
  </>
  )
}
