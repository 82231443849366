import { useState,useEffect,useRef } from "react";
import React from 'react';
import { Fade } from "react-awesome-reveal";

const Career = () => {

  const [careercont1, setCareercont11] = useState("careercont1")

  const listenScrollEvent = (event) => {
    if (window.scrollY > 20) {
      return setCareercont11("careercont11")
    } else if (window.scrollY < 40) {
      return setCareercont11("careercont1")
    } 
  }
  
  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
    return () =>
      window.removeEventListener('scroll', listenScrollEvent);
  },[]);

  
  return (<>
    <section className={`container-fluid careercont1 d-flex align-items-center justify-content-center ${careercont1}`} id="careercont1">
      <div className='row'>
        <div className='col col-sm-12 col-md-12 col-lg-12 align-self-bottom'>
          <h1 className='font_4'>Careers</h1>
        </div>
      </div>
    </section>

    <section className={`container-fluid career_mobile p-0 position-relative ${careercont1}`}>
        <img className="img-fluid w-100" src="/career/career_banner.jpg" />
        <h1 className="font_4 position-absolute top-50 start-50 translate-middle">Careers</h1>
    </section>

    <div className='container-fluid careercont3 pt-5 px-0 position-relative'>
      <section className='container careercont2 px-5'>
        <div className='row'>
          <div className='careercont2col col col-sm-11 col-md-11 col-lg-8 mx-auto p-5 position-absolute top-0 start-50 translate-middle'>
            <Fade>
            <img src='/leaf.png' id="leaf" width="90px" height="auto" />
            </Fade>
            <Fade cascade damping={0.1}>
            <p className='pargraph1 black'>Our customers are at the <span>heart</span> of everything we do, and we recognize the significance of assembling a team that <span>embodies</span> this core value.</p> <br/>
            <p className='pargraph2 black'>We relentlessly seek out <span>exceptional</span> talent who embody <span>generosity</span>, <span>teamwork</span>, and <span>leadership</span> at their essence to create a workforce that is <span>inclusive</span>, <span>diverse</span>, and reflective of the <span>communities</span> we serve.</p>
            </Fade>
          </div> 
        </div>
      </section>
      
      <section className='container-fluid careercont4'>
        <div className='row justify-content-center'>
          <div className='col col-sm-12 col-md-12 col-lg-8 text-center'>
            <h2 className='pb-2'>Empowering <em>People</em></h2>
            <Fade cascade damping={0.1}>
            <p className='pargraph2 black'>At Basil, we are committed to creating a <span>diverse</span> and <span>inclusive</span> environment that prioritises
              the <span>professional</span> and <span>personal development</span> of all members, regardless of background. Our
              efforts, which include training programmes and skill development workshops, seek to <span>empower</span> people from all walks of life and encourage <span>positive</span> change.</p>
              <br/> <br/>
              <p className='paragraph3 px-5'>If you believe you have what it takes to join our exceptional team, reach out to us at <a href="mailto:admin@basilhotels.com">admin@basilhotels.com</a></p>
              </Fade>
          </div>
        </div>
      </section>
      <Fade>
      <img src='/career/careers4.png' width="100%" className="team_l" />
      </Fade>
    </div>
  </>)
}
export default Career;