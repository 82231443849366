import React from 'react';
import { useState,useEffect,useRef } from 'react';
import video from "../assets/foodservices.mp4";
import fallback from "../assets/fallback2.jpg";
import { ExperienceSlider } from '../Components/ExperienceSlider';
import { FoodServicesSlider } from '../Components/FoodServicesSlider';
import { Fade } from 'react-awesome-reveal';

const FoodServices = () => {

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width:320px)").matches
  )
  useEffect(() => {
    window
    .matchMedia("(min-width:320px)")
    .addEventListener('change', e => setMatches( e.matches ));
  }, []);

  // const videoReff = useRef();

  // useEffect(() => {
  //   let options = {
  //     rootMargin: "0px",
  //     threshold: [0.25, 0.75]
  //   };
   
  //   let handlePlay = (entries, observer) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {videoReff.current.play();} else {videoReff.current.pause();}
  //     });
  //   };
  //   let observer = new IntersectionObserver(handlePlay, options);
  //   observer.observe(videoReff.current);
  // });

  const [showButton, setShowButton] = useState(true);
  const videoRef = useRef();
  const handlePlay = () => {
     setShowButton(!showButton);
      videoRef.current.play();
  }
  const handlePause = () => {
      videoRef.current.pause();
      setShowButton(!showButton);
     }
 

  return (<>
    <section className="container-fluid p-0 h_vid_cont font_1 position-relative">
      {showButton &&<div onClick={handlePlay}> <img src='play.png' className='img-fluid top-50 start-50 translate-middle play'/> </div> }
      {matches && (<video src={video} className="w-100 home_video" loop poster={fallback} ref={videoRef} onClick={handlePause}> </video>) }
    </section>

    <section className='container-fluid FS_cont_1 py-3 d-flex justify-content-center text-center'>
      <div className='col col-sm-12 col-md-10 col-lg-8 px-5 py-5'>
        <h3 className='mb-5'>Institutional <span>Food Services</span></h3>
        <Fade cascade delay={800}>
          <p>We have <span>flawlessly</span> upheld our status as the <span>best food service brand</span> for educational
          institutions for over two decades. Our constant commitment to the highest <span>quality</span>, <span>hygiene</span>,
          and flavours has cemented our position as the most <span>preferred</span> and <span>trusted</span> name in the
          sector. We presently serve Bengaluru's <span>leading and amongst the best</span> international
          schools.</p>
          </Fade>
      </div>
    </section>

    <section id="Nutritious" className='container-fluid FS_cont_2 align-items-center'>
      <div className='row justify-content-center'>
        <div className='col col-lg-8'>
          <Fade>
          <h3 className='font_1 text-center h33'>WHO WE SERVE</h3>
          </Fade>
        </div>
      </div>
      <div className='row justify-content-center pt-4'>
        <div className='col col-md-4 col-lg-4 align-self-center'>
          <Fade>
          <h2 className='font_3'>Nutritious Food<br /> for a<br /><span>Brighter Future</span></h2>
          </Fade>
        </div>
        <div className='col col-md-4 col-lg-2 align-self-center text-center'>
          <img src='/leaf.png' id="leaf" width="120px" height="auto" />
        </div>
        <div className='col col-md-4 col-lg-4 align-self-center'>
          <Fade delay={600}>
          <p className='para_small pt-4'>The young minds we <span>foster</span> deserve food that supports their <span>development</span> and <span>potential</span>. We provide day scholars, boarders, and staff with <span>nutritious</span>, <span>balanced</span>, and freshly
            prepared meals that will support them throughout the day.</p>
            </Fade>
        </div>
      </div>
    </section>

    <section className='container-fluid  FS_cont_4 ps-0'>
      <div className='row justify-content-center'>
        <div className='col col-sm-12 col-md-12 col-lg-6 col-xl-5'>
          <img src='/foodservices/foods.jpg' className='img-fluid' />
        </div>
        <div className='col col-sm-12 col-md-12 col-lg-6 col-xl-5 align-self-center ps-5 pe-5'>
          <Fade>
          <h4 className='h33 font_1 pb-3 pt-4'>WHAT WE DO</h4>
          </Fade>
          <Fade delay={600}>
          <h2 className='pb-2'>Our Institutional<br /><span>Menu Engineering</span></h2>
          </Fade>
          <Fade delay={800}>
          <p className='pargraph2'>We work with <span>nutritionists</span> to ensure that every menu is well-balanced. By combining our menu engineering and nutrition <span>expertise</span> with thoughtful ingredient <span>selection</span> and <span>diverse</span> flavours, we bring the 'globe to the plate', surpassing standard institutional dining.</p>
          </Fade>
        </div>
      </div>
    </section>

    <section className='container-fluid FS_cont_6 py-5 mb-5'>
      <div className='row justify-content-center text-center'>
        <div className='col col-md-10 col-lg-10 mx-auto text-center px-5 pb-3 array'>
          <h2>An Array of <span>Experiences</span></h2>
          <Fade delay={800}>
          <p className='px-5 pt-2'>We <span>facilitate</span> special events that bring people together, spread <span>happiness</span>, and help them make lasting <span>memories</span> through shared meals.</p>
          </Fade>
        </div>
      </div>
      <div className='row justify-content-center text-center row3'>
        <div className='col col-sm-4 col-md-4 col-lg-3'>
          <img src='/foodservices/ourcafe.png' className='img-fluid pb-3' />
          <h3>CAFETERIA</h3>
        </div>
        <div className='col col-sm-4 col-md-4 col-lg-3'>
          <img src='/foodservices/cafeteria.png' className='img-fluid pb-3' />
          <h3>OUT OF CAFETERIA</h3>
        </div>
        <div className='col col-sm-4 col-md-4 col-lg-3'>
          <img src='/foodservices/operations.png' className='img-fluid pb-3' />
          <h3>ANNUAL EVENTS</h3>
        </div>
      </div>
      <ExperienceSlider/>
    </section>
    

    <section className='container-fluid FS_cont_7 position-relative mt-5 pt-5'>
      <img src='/foodservices/cp.png' className=' position-absolute start-0' />
      <div className='row'>
        <div className='col col-sm-12 col-md-12 col-lg-12 FandB'>
          <h4 className='h33 font_1'>HOW WE DO IT</h4>
          <h2 className='pb-4'>F&B Production</h2>
          <Fade delay={800}>
          <p className=''>Food <span>safety</span> and <span>cleanliness</span> are especially <span>important</span> in schools since children are more susceptible to foodborne illnesses. Our operations involve daily and routine <span>procedures</span> that encompass sourcing and storing ingredients, food handling, regular training of personnel by food safety instructors, and routine food and inventory audits.</p>
          </Fade>
        </div>
      </div>
    </section>

    <section className='container pb-5 FS_cont_8'>
      <div className='row justify-content-center' id="lists">
        <div className='col col-sm-10 col-md-6 col-lg-5'>
          <h2 className='number pb-3'>1</h2>
          <h3 className='sheading pb-3'>Ingredients Handling & Storing</h3>
          <Fade delay={800}>
          <p>Our ingredients are bought, examined, arranged, and stored in accordance with the established guidelines, using recognised processes and with the appropriate labelling.</p>
          <br />
          </Fade>
          <div className='wrapper wrapper1'>
          <img src='/foodservices/roti1.png' className='img-fluid' />
          </div>
          <br />
          <h2 className='number pt-4 pb-3'>3</h2>
          <h3 className='sheading pb-3'>Service Handling & Supervising</h3>
          <Fade delay={800}>
          <p>We design cook, hold, and serve plans based on the institution's needs, with meticulous service standards, food setup, and clearance in the dining areas.</p>
          <br />
          </Fade>
          <div className='wrapper wrapper1'>
          <img src='/foodservices/handling1.png' className='img-fluid' />
          </div>
        </div>

        <div className='col col-sm-10 col-md-6 col-lg-4'>
          <div className='wrapper wrapper1'>
          <img src='/foodservices/capsicum1.png' className='img-fluid' />
          </div>
          <br />
          <h2 className='number pt-4 pb-3'>2</h2>
          <h3 className='sheading pb-3'>Kitchen Hygiene & Prep</h3>
          <Fade delay={800}>
          <p>Our prep staff manage the food preparation, cooking, and kitchen maintenance under the guidance of professional Commis chefs with extensive experience.</p>
          <br />
          </Fade>
          <div className='wrapper wrapper1'>
          <img src='/foodservices/service1.png' className='img-fluid' />
          </div>
          <br />
          <h2 className='number pt-4 pb-3'>4</h2>
          <h3 className='sheading pb-3'>Equipment Handling & Storing</h3>
          <Fade delay={800}>
          <p>Our well-trained staff handle cutting-edge kitchens effectively, serving nutritious meals and sticking to best practices when washing and storing production and service wares.</p>
          </Fade>
        </div>
      </div>
    </section>
  <FoodServicesSlider/>
  </>)
}
export default FoodServices;