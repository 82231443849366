import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay} from 'swiper/modules';

const OurStorySlider = () => {
  return (<>
      <Swiper
        loop={true}
        grabCursor={false}
        spaceBetween={30}
        speed={1000}
        autoplay={{ delay:2000, waitForTransition: true, }}
        modules={[Autoplay]}
        className="container-fluid Our_story_slider text-center">
          <SwiperSlide>
          <div className='col col-sm-8 service_lines'>
            <div className='wrapper'>
            <img src='/ourstory/01.jpg' className='img-fluid'/>
            </div>
            <h4>Institutional Food Services</h4>
            <p>Wholesome menu for cafeteria meals, special occasions, and annual events</p>
          </div>
          </SwiperSlide>

          <SwiperSlide>
          <div className='col col-sm-8 service_lines'>
          <div className='wrapper'>
            <img src='/ourstory/02.jpg' className='img-fluid'/>
            </div>
            <h4>Quick Service Restaurants</h4>
            <p>Quick and delicious meals for individuals on the go</p>
          </div>
          </SwiperSlide>

          <SwiperSlide>
          <div className='col col-sm-8 service_lines'>
          <div className='wrapper'>
            <img src='/ourstory/03.jpg' className='img-fluid'/>
            </div>
            <h4>Cafes and more</h4>
            <p>Ideal setting for social and professional gatherings over wonderful coffee and food</p>
          </div>
          </SwiperSlide>

        </Swiper>
  </>)
}

export default OurStorySlider